import { ReactComponent as SuccessIcon } from 'assets/svg/Success.svg'

import Preloader from 'components/Preloader'
import Typography from 'components/Typography'
import Paper from 'components/Paper'
import Button from 'components/Button'
import InvoiceInfo from 'components/InvoiceInfo'
import StyledLink from 'components/StyledLink'

import { AppRoute, PageHeading } from 'assets/const'
import { useInvoice } from 'hooks/data/useInvoice'
import { useReturnUrl } from 'hooks/useReturnUrl'

import s from './Success.module.scss'


const Success = () => {
  const { data, isLoading, isValidating } = useInvoice()
  const { returnUrl } = useReturnUrl()

  if (isLoading || isValidating) return <Preloader />

  return (
    <Paper className={s.content}>
      <Typography as='h1' variant='h4' style={{ marginBottom: '20px' }}>
        {PageHeading.Success}
      </Typography>
      <SuccessIcon />
      <div className={s.infoContainer}>
        <InvoiceInfo/>
      </div>
      <StyledLink to={ returnUrl || data?.redirectUrl || AppRoute.Root}>
        <Button className={s.doneButton}>
          Done
        </Button>
      </StyledLink>
    </Paper>
  )
}

export default Success
