import { Params } from 'assets/const'
import { useSearchParams } from 'react-router-dom'

export const useReturnUrl = () => {
  const [params] = useSearchParams()

  const encodedReturnUrl = params.get('returnUrl') ?? ''
  const returnUrl = decodeURIComponent(encodedReturnUrl)
  const returnUrlParam = `${Params.ReturnUrl}=${returnUrl}`

  return {
    returnUrl,
    returnUrlParam,
  }
}
