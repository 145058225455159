import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from 'components/Button'
import InvoiceInfo from 'components/InvoiceInfo'
import Paper from 'components/Paper'
import PaperHeader from 'components/PaperHeader'
import SkeletonLoader from 'components/SkeletonLoader'
import StyledLink from 'components/StyledLink'
import Typography from 'components/Typography'
import Preloader from 'components/Preloader'

import { AppRoute, Logo, PageHeading, PaymentSystemCode } from 'assets/const'
import { useInvoice } from 'hooks/data/useInvoice'
import { useMatchMedia } from 'hooks/useMatchMedia'
import { usePath } from 'hooks/usePath'
import { PaymentSystem } from 'types/invoice'
import { UserMangoStatus } from 'types/mango'
import { MangoService } from 'services/mango.service'
import { useSseStore } from 'store/useSseStore'
import { useReturnUrl } from 'hooks/useReturnUrl'

import s from './PaymentInfo.module.scss'


const getRouteName = (activeCode: string | null, state = null) => {
  switch(activeCode) {
    case PaymentSystemCode.STRIPE:
      return AppRoute.Root + AppRoute.StripePayment
    case PaymentSystemCode.MANGO:
      return AppRoute.Mango.Root + AppRoute.Mango.DictionaryStep
    case AppRoute.CW:
      return AppRoute.CW

    default:
      return AppRoute.Root +`${AppRoute.CryptoPayment}/${activeCode}`
  }
}

const PaymentInfo = () => {
  const {
    data: invoice,
    isLoading,
    isValidating,
    isNewStatus,
    cancelInvoice,
  } = useInvoice()
  const { isPendingCancellation, setIsPendingCancellation } = useSseStore()
  const {mangoUserStatus, setMangoUserStatus} = useSseStore()

  const { isTablet } = useMatchMedia()
  const { returnUrl } = useReturnUrl()

  const [activeItem, setActiveItem] = useState<PaymentSystem | undefined>(undefined)
  const navigate = useNavigate()
  const path = usePath(getRouteName(activeItem?.code || null))

  const onCancel = () => {
    if (invoice?.id) {
      setIsPendingCancellation(true)
      cancelInvoice(invoice.id).catch(() => setIsPendingCancellation(false))
    }
  }

  useEffect(() => {
    setActiveItem(invoice?.paymentSystems[0])
  }, [invoice])

  const handlePaymentSystemChange = (item: PaymentSystem) => {
    setActiveItem(item)
  }

  const handleContinue = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault()
    if (activeItem?.code === PaymentSystemCode.MANGO) {
      switch (mangoUserStatus) {
        case UserMangoStatus.created:
          navigate(path)
          return;
        case UserMangoStatus.pending:
          navigate(path)
          return;
        case UserMangoStatus.uncreated:
          setMangoUserStatus(UserMangoStatus.pending)
          MangoService.createMangoNaturalPayer()
          return
      }
    }
    else navigate(path)
  }

  return (
    <div className={s.wrapper}>
      <Paper>
        {(isPendingCancellation || isValidating) && <Preloader />}
        <PaperHeader text={PageHeading.PaymentInfo} />
        <div className={s.infoContainer}>
          <InvoiceInfo />
        </div>
        {isLoading ? (
          <div className={s.skeletonBtn}>
            <SkeletonLoader size='medium' />
          </div>
        ) : (
          <>
            {invoice && invoice.paymentSystems.length > 1 &&
            <>
            <Typography variant='h4' className={s.paymentText}>Choose payment system:</Typography>
            <div className={s.imageContainer}>
              {invoice?.paymentSystems.map((item) => (
                <div
                  className={s.iconBox} key={item.name}
                  style={{border: item === activeItem ? '2px var(--color-primary) solid' : ''}}
                  onClick={() => handlePaymentSystemChange(item)}
                >
                  {Logo[item.code as keyof typeof Logo]}
                    {/* <img
                      src={item.icon || Logo[item.code as keyof typeof Logo] || ''}
                      height={30}
                      width={'auto'}
                      alt='payment icon'
                      style={{ objectFit: 'contain' }}
                    /> */}
                </div>
                ))}
              </div>
            </>
          }
          <div className={s.buttonsContainer}>
            {isNewStatus ? (
              <>
                <Button
                  variant='outlined'
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button disabled={!activeItem} onClick={handleContinue}>
                  Continue
                </Button>
              </>
            ) : (
              invoice?.redirectUrl && (
                <StyledLink to={returnUrl || invoice?.redirectUrl} fullWidth={!isTablet}>
                  <Button as='span' fullWidth>
                    Close
                  </Button>
                </StyledLink>
              )
            )}
          </div>
          </>
        )}
      </Paper>
    </div>
  )
}

export default PaymentInfo
