import { lazy } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import PrivateInvoiceRoute from 'components/PrivateInvoiceRoute'
import PrivateTokenRoute from 'components/PrivateTokenRoute'
import StripeComplete from 'components/StripeComplete'
import CryptoPayment from 'pages/CryptoPayment'
import Profile from 'pages/Profile/Profile'
import UserPaymentSettings from 'pages/UserPaymentSettings/UserPaymentSettings'
import MainLayout from './layouts/MainLayout/MainLayout'
import NotFound from './pages/NotFound'
import PaymentInfo from './pages/PaymentInfo'
import StripeProfile from './pages/StripeProfile'
import Success from './pages/Success'
import BankAccounts from './pages/ExternalAccounts/Bank/BankAccounts'
import MangoDictionaryStep from './pages/Mango/DictionaryStep';
import MangoPaymentInfoPage from './pages/Mango/MangoPaymentInfoPage'
import CWPayment from 'pages/CWPayment'
import MangoKyc from './pages/Mango/Settings/MangoKyc';
import MangoProfile from 'pages/Mango/Settings/MangoProfile'
import MangoBankAccounts from 'pages/Mango/Settings/ExternalAccounts/BankAccounts'

import { AppRoute } from './assets/const'


const StripePayment = lazy(() => import('./pages/StripePayment'))
const MangoPayment = lazy(() => import('./pages/Mango/MangoPayment'))

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MainLayout />}>
          <Route element={<PrivateTokenRoute />}>
            <Route path={AppRoute.Root} element={<PaymentInfo />} />
            <Route element={<PrivateInvoiceRoute />}>
              <Route
                path={AppRoute.StripePayment}
                element={<StripePayment />}
              />
              <Route
                path={AppRoute.CW}
                element={<CWPayment />}
              />
              <Route path={AppRoute.Mango.Root}>
                <Route path={AppRoute.Mango.Payment} element={<MangoPayment />} />
                <Route path={`${AppRoute.Mango.Payment}/${AppRoute.Mango.PaymentStatus}`} element={<MangoPaymentInfoPage />}/>
                <Route path={AppRoute.Mango.DictionaryStep} element={<MangoDictionaryStep />}/>
              </Route>
              <Route
                path={`${AppRoute.CryptoPayment}/:${AppRoute.Params.PaymentId}`}
                element={<CryptoPayment />}
              />
            </Route>
            <Route path={AppRoute.Success} element={<Success />} />
          </Route>
          <Route path={AppRoute.Settings} element={<Profile />}>
            <Route index element={<Navigate to={AppRoute.PaymentSettings} />} />
            <Route
              path={AppRoute.PaymentSettings}
              element={<UserPaymentSettings />}
            />
            <Route
              path={AppRoute.Stripe}
            >
              <Route
                index
                path={AppRoute.Profile}
                element={<StripeProfile/>}
              />
              <Route
                path={AppRoute.ExternalAccounts.Bank}
                element={<BankAccounts />}
              />
              {/*<Route*/}
              {/*  path={`${AppRoute.Profile}/${AppRoute.ExternalAccounts.Card}`}*/}
              {/*  element={<CardAccounts />}*/}
              {/*/>*/}
              <Route
                path={AppRoute.Completed}
                element={<StripeComplete />}
              />
            </Route>

            <Route
              path={'mango'}
            >
              <Route
                index
                path={AppRoute.Profile}
                element={<MangoProfile/>}
              />
              <Route
                path={AppRoute.AccountVerification}
                element={<MangoKyc />}
              />
              <Route
                path={`${AppRoute.ExternalAccounts.Bank}`}
                element={<MangoBankAccounts />}
              />
            </Route>

          </Route>
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
