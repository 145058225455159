import { useEffect, useState } from 'react'

import Button from 'components/Button'
import LinearProgressBar from 'components/LinearProgressBar'
import Preloader from 'components/Preloader'
import StyledLink from 'components/StyledLink'

import { AppRoute, ProgressBarTitle } from 'assets/const'
import { UserService } from 'services/user.service'
import { useSseStore } from 'store/useSseStore'
import { StripeAccountLink } from 'types/user-profile'

import s from './StripeEdit.module.scss'


interface Props {
  stripeAccountLink?: StripeAccountLink
  accountId: string
  onCancelClick: () => void
}

const MILLISECONDS_MULTIPLIER = 1000

const StripeEdit = ({ stripeAccountLink, accountId, onCancelClick }: Props) => {
  const [isExpired, setIsExpired] = useState<boolean>(false)
  const { setStripeAccountLink } = useSseStore()
  const linkLife = stripeAccountLink
    ? (Number(stripeAccountLink?.expiredAt) -
        Number(stripeAccountLink?.created)) *
      MILLISECONDS_MULTIPLIER
    : 0
  const returnLink = `${window.location.origin}/${AppRoute.Settings}/${AppRoute.Stripe}/${AppRoute.Completed}${window.location.search}`

  // console.log('created:', stripeAccountLink?.created)
  // console.log('now:', getTimeStump() / 1000)
  // console.log('expiredAt:', stripeAccountLink?.expiredAt)
  // console.log(getTime(Number(stripeAccountLink?.created)))
  // console.log(getTime())
  // console.log(getTime(Number(stripeAccountLink?.expiredAt)))

  useEffect(() => {
    if (stripeAccountLink) return

    UserService.createStripeLinkSse({
      connectedAccountId: accountId,
      refresh_url: window.location.href,
      return_url: returnLink,
    })
    return () => {
      setStripeAccountLink(undefined)
    }
  }, [])

  const refreshHandler = () => {
    UserService.createStripeLinkSse({
      connectedAccountId: accountId,
      refresh_url: window.location.href,
      return_url: returnLink,
    })
    setStripeAccountLink(undefined)
    setIsExpired(false)
  }

  if (!stripeAccountLink) {
    return (
      <div className={s.preloader}>
        <Preloader />
      </div>
    )
  }

  return (
    <>
      <LinearProgressBar
        awaitingTitle={ProgressBarTitle.AwaitingLink}
        expiredTitle={ProgressBarTitle.ExpiredLink}
        timeout={linkLife}
        created={Number(stripeAccountLink?.created) * MILLISECONDS_MULTIPLIER}
        onExpired={setIsExpired}
      />
      <div className={`${s.buttonsContainer} ${s.accountLink}`}>
        <StyledLink
        // fullWidth={isMobile}
          to={stripeAccountLink.url}
          onClick={(evt) => {
            if (isExpired) {
              evt.preventDefault()
              refreshHandler()
            }
          }}
        >
          <Button as='span' fullWidth>
            {isExpired ? 'Refresh link' : 'Redirect to Stripe'}
          </Button>
        </StyledLink>
        <Button variant='outlined' onClick={onCancelClick}>
          Cancel
        </Button>
      </div>
    </>
  )
}
export default StripeEdit
